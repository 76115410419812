<!-- =========================================================================================
	File Name: TheNavbar.vue
	Description: Navbar component
	Component Name: TheNavbar
	----------------------------------------------------------------------------------------
	Item Name: Vuesax Admin - VueJS Dashboard Admin Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="relative">
    <div class="vx-navbar-wrapper" style="height: 80px; position: relative; background: #3d5a80; color: white"
    >
      <div class="navbarContent">
        <vs-navbar style="position: relative; width: 100%;" class="vx-navbar navbar-custom" :color="navbarColor"
                   :class="classObj">

          <div style="font-size: 32px">{{ $t("welcome") }} {{ user_displayName }}</div>
          <!-- SM - OPEN SIDEBAR BUTTON -->

          <vs-spacer></vs-spacer>
          <!-- USER META -->

          <div class="the-navbar__user-meta flex items-center">

            <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer flex justify-space-between">
              <div class="manuIconWrapper"><span style="color: #3d5a80" class="material-icons">menu</span></div>
              <div class="con-img mr-3 manuImgWrapper">
                <img
                    v-if="activeUserImg.startsWith('http')"
                    key="onlineImg"
                    :src="activeUserImg"
                    alt="user-img"
                    width="30"
                    height="30"
                    class="rounded-full shadow-md cursor-pointer block"/>
                <img
                    v-else
                    key="localImg"
                    src="../../assets/images/userLogo.png"
                    alt="user-img"
                    width="30"
                    height="30"
                    class="rounded-full shadow-md cursor-pointer block"/>
              </div>

              <vs-dropdown-menu>

                <ul style="min-width: 9rem">
                  <vs-divider class="m-1"></vs-divider>

                  <!--<div style="font-weight: bold" class="text-center leading-tight hidden sm:block">
                    <p style="padding-top: 10px" class="font-semibold">{{ user_displayName }}</p>

                  </div>
                  <hr>-->
                  <div style="text-align: center; cursor: pointer; margin-top: 15px">
                    <span
                        :class="{'activeLang': this.$moment.locale(locale)==='tr'}" @click="updateLocale('tr')">

                    <img
                        src="../../assets/images/tr.png"
                        class="resize_fit_center mr-2" width="30">

                  </span>
                    /
                    <span :class="{'activeLang': this.$moment.locale(locale)==='en'}"
                          @click="updateLocale('en')">

                      <img
                          src="../../assets/images/en.png"
                          class="resize_fit_center mr-2" width="30">

                    </span>/
                    <span :class="{'activeLang': this.$moment.locale(locale)==='cn'}"
                          @click="updateLocale('cn')">

                      <img
                          src="../../assets/images/cn.png"
                          class="resize_fit_center mr-2" width="30">

                    </span>
                  </div>
                  <hr style="margin-top: 10px; margin-bottom: 5px">
                  <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                      @click="logOut">
                    <div style="width: 100%; display: flex; justify-content: center">
                      <div>
                        <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"></feather-icon>
                      </div>
                      <div><span class="ml-2 " style="text-align: center">{{ $t("logout") }}</span></div>
                    </div>
                  </li>

                  <!--  <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                        @click="showStoreElements()">
                        <div style="display:flex; flex-direction: column">

                            <span>{{store}}</span>


                        </div>

                        <hr>
                    </li>-->
                  <div v-show="showStore">


                  </div>


                </ul>
              </vs-dropdown-menu>
            </vs-dropdown>


          </div>


        </vs-navbar>
      </div>
      <div style="position: absolute; top: 100px; right: 10px; height: 100%">
        <div style="position: relative;  height: 100vh; width: 100%; display: flex; flex-direction: column">
          <div :key="index" v-for="(item,index) in notifList" class="notifAlertActive ">
            <div style="text-align: center; font-weight: bold">{{ $t("newTransfer") }}</div>
            <div>
              {{item.Company}} entered the transfer of {{item.Transport }} {{item.ProductName }}s to {{item.Branch }} by {{item.ShipVia }}.
            </div>

          </div>


        </div>


      </div>

    </div>
  </div>
</template>

<script>
import VxAutoSuggest from '@/components/vx-auto-suggest/VxAutoSuggest.vue';
import NotificationButton from '../../views/components/NotificationButton.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import BoldIcon from "vue-feather-icons/icons/BoldIcon";
import MenuIcon from "vue-material-design-icons/Menu.vue"
import Notifications from "../../views/components/vuesax/notifications/Notifications";
import config from '../../../config';
import {EventBus} from "@/event-bus";
import router from "@/router";
import NavbarMenuItem from "@/views/components/NavbarMenuItem";


export default {
  name: "the-navbar",
  props: {
    navbarColor: {
      type: String,
      default: "#fff",
    },
  },
  data() {

    return {

      notifList: [],
      user_displayName: localStorage.getItem('user_name'),
      user_id: localStorage.getItem('user_id'),

      showStore: false,
      betweenStoresFilter: false,
      storesDetailFilter: false,
      betweenProductsFilter: false,
      productDetailFilter: false,
      campaignModal: false,
      pageName: '',
      selectedGT: {
        name: "lostSales",
        id: 1

      },
      GTList: [
        {
          name: 'lostSales',
          id: 1
        },
        {
          name: 'stockOptimization',
          id: 2
        },

      ],
      dropDownGT: false,
      selectedGtType: localStorage.getItem("GTLastDrop")
    }
  },
  watch: {
    '$route'() {
      if (this.showBookmarkPagesDropdown) this.showBookmarkPagesDropdown = false
    },

  },
  mounted() {
    if (this.user_displayName === "Admin" || this.user_displayName === "admin") {
      setInterval(() => {
        this.notifControl();

      }, 10000);
    }


    this.$i18n.locale = localStorage.getItem("locale");
    EventBus.$on('navbarColorCode', (code) => {
      this.navbarColorCod = code;

    });
    /*  EventBus.$on('locale', (locale) => {
        this.$i18n.locale = locale
        console.log("Dil bu", locale)

      });*/
    EventBus.$on('pageName', (code) => {
      this.pageName = code;

    });
    EventBus.$on('campaignModal', (campaignModal) => {
      this.this.campaignModal = campaignModal;

    });
    EventBus.$on('selectedCampaignName', (selectedCampaign) => {
      this.selectedCampaign = selectedCampaign;
      localStorage.setItem("campaignName", JSON.stringify(this.selectedCampaign))

    });


  },
  computed: {
    // HELPER
    sidebarWidth() {
      return this.$store.state.sidebarWidth;
    },
    breakpoint() {
      return this.$store.state.breakpoint;
    },

    // NAVBAR STYLE
    classObj() {
      if (this.sidebarWidth == "default") return "navbar-default"
      else if (this.sidebarWidth == "reduced") return "navbar-reduced"
      else if (this.sidebarWidth) return "navbar-full"
    },

    // I18N
    getCurrentLocaleData() {
      if (localStorage.getItem("locale")) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.$i18n.locale = localStorage.getItem("locale");
      }
      const locale = this.$i18n.locale;
      if (locale == "en") return {flag: "us", lang: 'English'}
      else if (locale == "pt") return {flag: "br", lang: 'Portuguese'}
      else if (locale == "tr") return {flag: "tr", lang: 'Türkçe'}
      else if (locale == "fr") return {flag: "fr", lang: 'French'}
      else if (locale == "de") return {flag: "de", lang: 'German'}
    },

    // BOOKMARK & SEARCH
    data() {
      return this.$store.state.navbarSearchAndPinList;
    },
    starredPages() {
      return this.$store.state.starredPages;
    },
    starredPagesLimited: {
      get() {
        return this.starredPages.slice(0, 10);
      },
      set(list) {
        this.$store.dispatch('arrangeStarredPagesLimited', list);
      }
    },
    starredPagesMore: {
      get() {
        return this.starredPages.slice(10);
      },
      set(list) {
        this.$store.dispatch('arrangeStarredPagesMore', list);
      }
    },

    // CART DROPDOWN
    cartItems() {
      return this.$store.state.eCommerce.cartItems.slice().reverse();
    },

    // PROFILE
    user_displayName() {
      return localStorage.getItem('user_name')
    },
    activeUserImg() {
      return this.$store.state.AppActiveUser.img;
    }
  },
  methods: {
    notifControl() {
      this.notifList = []
      this.$http.get(`${config.API_BASE}/api/v1/notification`, this.config).then((response) => {
        console.log("NOT", response.data)
        this.notifList = response.data

      });
    },
    changeSelectedGtType(item) {
      this.selectedGtType = item
      this.dropDownGT = false
      EventBus.$emit("GtType", item);
      localStorage.setItem("GTLastDrop", item);
      /*  const activeDrop = this.activeTable || localStorage.getItem("GTLastDrop");
        switch(activeDrop) {
          case 'LostSales':
            return this.lostSalesFunc();
          case 'StockOpt':
            return this.stockOpFunc();
          default:
            return this.lostSalesFunc();

        }*/
    },
    openGtBase() {
      this.dropDownGT = !this.dropDownGT

    },
    goToHome() {
      router.push({path: '/order',})
      this.pageName = 'order'
      EventBus.$emit("goMainPage");
    },
    showStoreElements() {
      this.showStore = true
    },
    /* storesName() {
         this.$http.get(`${config.API_BASE_SANDBOX}/merchant/${this.merchant_id}/store`, this.config
         ).then(response => {
             this.StoreList = response.data.data;

         }).catch((error) => {

             console.log(error)

         });
     },*/

    updateLocale(locale) {
      this.$i18n.locale = locale;
      this.$moment.locale(locale);
      localStorage.setItem("locale", locale);
      EventBus.$emit("locale", locale);
    },
    UpdateStore(item) {
      this.store = localStorage.setItem("store", item.name);
      this.store_id = localStorage.setItem("store_id", item.id);
      return location.reload();
    },

    showSidebar() {
      this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
    selected(item) {
      this.$router.push(item.url);
      this.showFullSearch = false;
    },
    actionClicked(item) {
      // e.stopPropogation();
      this.$store.dispatch('updateStarredPage', {index: item.index, val: !item.highlightAction});
    },
    showNavbarSearch() {
      this.showFullSearch = true;
    },
    showSearchbar() {
      this.showFullSearch = true;
    },
    elapsedTime(startTime) {
      let x = new Date(startTime);
      let now = new Date();
      var timeDiff = now - x;
      timeDiff /= 1000;

      var seconds = Math.round(timeDiff);
      timeDiff = Math.floor(timeDiff / 60);

      var minutes = Math.round(timeDiff % 60);
      timeDiff = Math.floor(timeDiff / 60);

      var hours = Math.round(timeDiff % 24);
      timeDiff = Math.floor(timeDiff / 24);

      var days = Math.round(timeDiff % 365);
      timeDiff = Math.floor(timeDiff / 365);

      var years = timeDiff;

      if (years > 0) {
        return years + (years > 1 ? ' Years ' : ' Year ') + 'ago';
      } else if (days > 0) {
        return days + (days > 1 ? ' Days ' : ' Day ') + 'ago';
      } else if (hours > 0) {
        return hours + (hours > 1 ? ' Hrs ' : ' Hour ') + 'ago';
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? ' Mins ' : ' Min ') + 'ago';
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? `${seconds} sec ago` : 'just now');
      }

      return 'Just Now'
    },
    logOut() {

      setTimeout(() => {
        this.$router.push({name: 'pageLogin'})
      }, 100);

    },
    outside: function () {
      this.showBookmarkPagesDropdown = false
    },

    // CART DROPDOWN
    removeItemFromCart(item) {
      this.$store.dispatch('eCommerce/toggleItemInCart', item)
    }
  },
  directives: {
    'click-outside': {
      bind: function (el, binding) {
        const bubble = binding.modifiers.bubble
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e)
          }
        }
        el.__vueClickOutside__ = handler
        document.addEventListener('click', handler)
      },

      unbind: function (el) {
        document.removeEventListener('click', el.__vueClickOutside__)
        el.__vueClickOutside__ = null

      }
    }
  },
  components: {
    NavbarMenuItem,
    Notifications,
    BoldIcon,
    VxAutoSuggest,
    VuePerfectScrollbar,
    draggable,
    NotificationButton,
    MenuIcon
  },
}
</script>
<style lang="scss">
.notifAlertActive {
  background: #3d5a80 ;
  width: 300px;
  height: auto;
  padding: 10px;
  z-index: 99999;
  border-radius: 4px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;

}

@keyframes mymove {
  from {
    top: -100px;
  }

  to {
    top: 5px
  }
}


.langStyle {
  margin-right: 60px;
  color: #333333;
}

.vx-navbar-wrapper {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  max-width: none !important;

  .navbarContent {
    max-width: 1600px;
    margin: auto;

  }
}

@media screen and (max-width: 479px) {
  /* start of phone styles */

  .langStyle {
    margin-right: 10px;
  }

}

.vs-dropdown--custom {
  width: max-content;
}

.storeDropDown {
  position: absolute;
}

.StoreListStyle {
  background: #ffffff;
  color: #c00829;
  padding: 10px 20px;
  font-size: 12px;

}

.StoreListStyle:hover {
  background: rgba(241, 241, 241, 0.53);


}

.campaignWrapper {
  display: flex;
  justify-content: center;
  margin: auto;
  position: absolute;
  right: 0;
  left: 0;
  width: 40%;
  font-size: 20px;
  color: white;
  font-weight: bold;

}

.activeLang {
  color: #c10028;
  font-weight: bold;
}

.the-navbar__user-meta {
  background: white;
  border-radius: 20px;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: space-between;

  .manuIconWrapper {

    width: 50px;
    margin-top: 7px;
  }

  .manuImgWrapper {

    margin-top: 3px;
    padding-left: 10px;
  }
}

.GTDropDownList {
  color: grey;
  padding: 0 5px;
  cursor: pointer;
  margin-bottom: 5px;
}

.GTDropDownList:hover {
  background: #f1f1f1;
}

// Full width notification in mobile devices
@media screen and (max-width: 500px) {

  .allWrapper .summaryContainer .summarySection {
    flex-direction: column;
  }
  .allWrapper .summaryContainer .summarySection .InteractionFunnelsCard, .allWrapper .summaryContainer .summarySection .performanceCardSmall, .allWrapper .summaryContainer .summarySection .performanceCard, .allWrapper .summaryContainer .summarySection .smallperformanceCard {
    width: 100% !important;
  }
  .allWrapper .summaryContainer .summarySectionWinner .winnerLoserCard {
    width: 47%;
  }
  .allWrapper .filterTitle {
    font-size: 14px;
  }
  .mobileHidden {
    display: none !important;
  }
}

// Full width notification in tablet devices
@media (min-width: 768px) and (max-width: 1024px) {


}

@media only screen and (max-width: 1271px) /*  start of Large styles */
{
  .filterModalDrop {
    padding: 0 20px;
    background: white;
    width: auto;
    height: 485px;
    box-shadow: 0 2px 30px 0 #00000010;
    border-radius: 10px;
    position: absolute;


  }
  .filterModalDropAverage {
    padding: 0 20px;
    background: white;
    width: auto;
    height: 485px;
    box-shadow: 0 2px 30px 0 #00000010;
    border-radius: 10px;
    position: absolute;


  }
}

// Full width notification in mobile devices
@media screen and (max-width: 500px) {

}


</style>
