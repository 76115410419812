<template>
<div style="display: flex" class="menuWrapper2">
  <div style="display: flex" :key="index"  v-for="(item,index) in datas">
    <b-dropdown id="dropdown-offset" offset="25" :text=$t(item.name)

                class="m-2">
      <b-dropdown-item :key="indexM" v-for="(itemM,indexM) in item.menu"
                       @click="goNewPage(item.name,itemM.name)">
        {{ $t(itemM.name) }}
      </b-dropdown-item>
    </b-dropdown>
    <!-- <div class="navbarMenuWrapper" style="display: flex; flex-direction: column" v-for="(item,index) in datas"
          :key="index">
       <div style="display:flex; cursor: pointer">
         <div @click="openMenuModalFunc(index)">{{ $t(item.name) }}</div>
         <span @click="openMenuModalFunc(index)" class="material-icons" v-if="openMenuModal===index">expand_less</span>
         <span @click="openMenuModalFunc(index)" class="material-icons" v-if="openMenuModal!==index">expand_more</span>
       </div>
       <div class="navbarMenu" v-if="openMenuModal===index">
         <div class="menuItem" v-for="(itemM,indexM) in item.menu" :key="indexM">
           <div @click="goNewPage(item.name,itemM.name)">{{ $t(itemM.name) }}</div>
         </div>
       </div>

     </div>-->
  </div>
</div>



</template>

<script>


import {EventBus} from "@/event-bus";

export default {


  props: ["datas"],
  data() {
    return {
      openMenuModal: false
    }
  },
  watch: {},
  components: {},
  mounted() {
    EventBus.$on('goMainPage', (filters) => {
      this.openMenuModal = 1111


    });
  },
  methods: {
    openMenuModalFunc(index) {
      if (this.openMenuModal === index) {
        this.openMenuModal = 1111
      } else {
        this.openMenuModal = index
      }

    },
    goNewPage(item, itemM) {
      console.log("şuraya", item, itemM)

      if (item === 'stockManagement') {
        this.$router.push({name: 'generalTracking'})
      } else {
        this.$router.push({name: item})
      }
      this.openMenuModal = 1111
      this.lastTabFunc(itemM)


    },
    lastTabFunc(itemM){
      if(itemM==='betweenStores'){
        localStorage.setItem("OpemLastTab", "BetweenStores");
        localStorage.setItem("GTLastTab", "BetweenStores");
        localStorage.setItem("CMLastTab", "BetweenStores");
        localStorage.setItem("PPLastTab", "BetweenStores");

      }
      else if(itemM==='overview'){
        localStorage.setItem("OpemLastTab", "Overview");
        localStorage.setItem("GTLastTab", "Overview");
        localStorage.setItem("CMLastTab", "Overview");
        localStorage.setItem("PPLastTab", "Overview");

      }
      else if(itemM==='betweenProducts'){
        localStorage.setItem("OpemLastTab", "BetweenProducts");
        localStorage.setItem("GTLastTab", "BetweenProducts");
        localStorage.setItem("CMLastTab", "BetweenProducts");
        localStorage.setItem("PPLastTab", "BetweenProducts");

      }
      else if(itemM==='storeDetail'){
        localStorage.setItem("OpemLastTab", "StoreDetail");
        localStorage.setItem("GTLastTab", "StoreDetail");
        localStorage.setItem("CMLastTab", "StoreDetail");
        localStorage.setItem("PPLastTab", "StoreDetail");

      }
      else if(itemM==='productDetail'){
        localStorage.setItem("OpemLastTab", "ProductDetail");
        localStorage.setItem("GTLastTab", "ProductDetail");
        localStorage.setItem("CMLastTab", "ProductDetail");
        localStorage.setItem("PPLastTab", "ProductDetail");

      }

    }
  }
}
</script>

<style lang="scss">
.btn-secondary, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle{
  background: transparent;
  color: #3d5a80;
  font-weight: bold;
  border: none!important;
}

.btn-group > .btn:focus, .btn:hover{
  background: transparent;
  color: #3d5a80;
  font-weight: bold;
  border: none!important;
}
.dropdown-menu.show{
  width: 100%;
  left: -20px!important;
  margin-top: 10px;
  color: #3d5a80!important;
  li{
    border-bottom: 1px solid #e9e9e9;

    &:last-child{
      border-bottom: none;
    }
  }
}

.navbarMenuWrapper {
  position: relative;
  width: auto;
  margin-right: 20px;
  display: flex;
}

.navbarMenu {
  background: white;
  width: 100%;
  min-width: 150px;
  height: auto;
  border-radius: 10px;
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 99999;
  padding-bottom: 2px;


}

.menuItem {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid #e9e9e9;
  color: black;
  cursor: pointer;
}

.menuItem:last-child {
  border-bottom: none;
}

.menuItem:hover {
  font-weight: bold;
}
// Full width notification in tablet devices
@media (min-width: 768px) and (max-width: 1024px) {


}
@media only screen and (max-width: 1271px) /*  start of Large styles */
{
  .filterModalDrop {
    padding: 0 20px;
    background: white;
    width: auto;
    height: 485px;
    box-shadow: 0 2px 30px 0 #00000010;
    border-radius: 10px;
    position: absolute;


  }
  .filterModalDropAverage {
    padding: 0 20px;
    background: white;
    width: auto;
    height: 485px;
    box-shadow: 0 2px 30px 0 #00000010;
    border-radius: 10px;
    position: absolute;


  }
}
// Full width notification in mobile devices
@media screen and (max-width: 500px) {
  .menuWrapper2{
    display: none!important;

  }
}
</style>
