/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [

    /* {
        header: "MANAGEMENT",
        i18n: "MANAGEMENT",
       },*/


    {
        url: '/summary',
        name: "summary",
        slug: "summary",
        i18n: "homeSideBar",
        icon: "/icons/home-run.svg",
        iconActive: "/icons/home-runRED.svg",



    },

    {
        url: null,
        name: "Security",
        slug: "Security",
        i18n: "reportsSideBar",
        icon: "/icons/analysis.svg",
        iconActive: "/icons/Raporlar.svg",

        submenu: [
            {
                url: '/dailyReportFilters',
                name: "dailyReport",
                slug: "dailyReport",
                i18n: "dailyReportSideBar",
            },
            {
                url: '/shoppingBehaviorReport',
                name: "shoppingBehaviorReport",
                slug: "shoppingBehaviorReport",
                i18n: "visitorBehaviorReportSideBar",
            },
            {
                url: '/productSurveillanceReport',
                name: "productSurveillanceReport",
                slug: "productSurveillanceReport",
                i18n: "productSurveillanceReportSideBar",
            },
            /*{
                url: '/stockQualityReport',
                name: "stockQualityReport",
                slug: "stockQualityReport",
                i18n: "stockQualityReport",
            },*/

        ]
    },
    {
        url: null,
        name: "Security",
        slug: "Security",
        i18n: "conversionSideBar",
        icon: "/icons/filter.svg",
        iconActive: "/icons/Conversions.svg",

        submenu: [
            {
                url: '/productPerformanceNew2',
                name: "productPerformance",
                slug: "productPerformance",
                i18n: "productPerformanceSideBar",
            },
            {
                url: '/zonePerformance',
                name: "zonePerformance",
                slug: "zonePerformance",
                i18n: "zonePerformanceSideBar",
            },


        ]
    },

  /* {
        url: '/experimentation',
        name: "experimentation",
        slug: "experimentation",
        i18n: "experimentationsSideBar",
        icon: "/icons/ab-testing.svg",
        iconActive: "/icons/Experimentations.svg",


    },*/

    {
        url: null,
        name: "Security",
        slug: "Security",
        i18n: "productSurveillanceSideBar",
        icon: "/icons/cctv.svg",
        iconActive: "/icons/Surveillance.svg",

        submenu: [
          /*  {
                url: '/storeSecurity',
                name: "storeSecurity",
                slug: "storeSecurity",
                i18n: "surveillanceSummarySideBar",

            },*/
            {
                url: '/SecurityMonitoring',
                name: "securityMonitoring",
                slug: "securityMonitoring",
                i18n: "storeMonitoringSideBar",
            },
            {
                url: '/alarmHistory',
                name: "alarmHistory",
                slug: "alarmHistory",
                i18n: "alarmHistorySideBar",
            },
          /*  {
                url: '/riskyItem',
                name: "riskyItem",
                slug: "riskyItem",
                i18n: "alarmedItemsSideBar",
            },*/
            {
                url: '/missingItem',
                name: "missingItem",
                slug: "missingItem",
                i18n: "missingItemsSideBar",
            },



        ]
    },

    {
        url: null,
        name: "Security",
        slug: "Security",
        i18n: "StockManagementSideBar",
        icon: "/icons/order.svg",
        iconActive: "/icons/StockManagement.svg",

        submenu: [
            {
                url: '/StoreStockManagement',
                name: "storeStockManagement",
                slug: "StoreStockManagement",
                i18n: "storeStockCountSideBar",
            },
           {
                url: '/ItemStockManagement',
                name: "itemStockManagement",
                slug: "ItemStockManagement",
                i18n: "itemStockCountSideBar",
            }

        ]
    },

    {
        url: '/retailManagement',
        name: "retailManagement",
        slug: "retailManagement",
        i18n: "retailManagementSideBar",
        icon: "/icons/task.svg",
        iconActive: "/icons/RetailManagement.svg",

    },
    {
        url: '/developers',
        name: "developers",
        slug: "developers",
        i18n: "developers",
        icon: "/icons/task.svg",
        onlyFarfetch:9,
        iconActive: "/icons/RetailManagement.svg",

    },
    {
        url: '/developers',
        name: "developers",
        slug: "developers",
        i18n: "developers",
        icon: "/icons/task.svg",
        onlyFarfetch:3,
        iconActive: "/icons/RetailManagement.svg",

    },
    {
        url: '/developers',
        name: "developers",
        slug: "developers",
        i18n: "developers",
        icon: "/icons/task.svg",
        onlyFarfetch:5,
        iconActive: "/icons/RetailManagement.svg",

    },
    {
        url: '/developers',
        name: "developers",
        slug: "developers",
        i18n: "developers",
        icon: "/icons/task.svg",
        onlyFarfetch:12,
        iconActive: "/icons/RetailManagement.svg",

    },

/*
      {
                header: "UI Elements",
                i18n: "UIElements",
            },
            {
                url: null,
                name: "Data List",
                slug: "dataList",
                icon: "ListIcon",
                i18n: "DataList",
                submenu: [
                    {
                        url: '/ui-elements/data-list/list-view',
                        name: "List View",
                        slug: "dataListListView",
                        i18n: "ListView",
                    },
                    {
                        url: '/ui-elements/data-list/thumb-view',
                        name: "Thumb View",
                        slug: "dataListThumbView",
                        i18n: "ThumbView",
                    },
                ]
            },
            {
                url: null,
                name: "Grid",
                slug: "grid",
                icon: "LayoutIcon",
                i18n: "Grid",
                submenu: [
                    {
                        url: '/ui-elements/grid/vuesax',
                        name: "Vuesax",
                        slug: "gridVuesax",
                        i18n: "Vuesax",
                    },
                    {
                        url: '/ui-elements/grid/tailwind',
                        name: "Tailwind",
                        slug: "gridTailwind",
                        i18n: "Tailwind",
                    },
                ]
            },
            {
                url: "/ui-elements/colors",
                name: "Colors",
                slug: "colors",
                icon: "DropletIcon",
                i18n: "Colors",
            },
            {
                url: null,
                name: "Card",
                slug: "card",
                icon: "CreditCardIcon",
                i18n: "Card",
                submenu: [
                    {
                        url: '/ui-elements/card/basic',
                        name: "Basic",
                        slug: "basicCards",
                        i18n: "Basic",
                    },
                    {
                        url: '/ui-elements/card/statistics',
                        name: "Statistics",
                        slug: "statisticsCards",
                        i18n: "Statistics",
                    },
                    {
                        url: '/ui-elements/card/analytics',
                        name: "Analytics",
                        slug: "analyticsCards",
                        i18n: "Analytics",
                    },
                    {
                        url: '/ui-elements/card/card-actions',
                        name: "Card Actions",
                        slug: "cardActions",
                        i18n: "CardActions",
                    }
                ]
            },
            {
                url: "/ui-elements/table",
                name: "Table",
                slug: "table",
                icon: "GridIcon",
                i18n: "Table",
            },
            {
                url: null,
                name: "Components",
                icon: "ArchiveIcon",
                tag: "21",
                slug: 'components',
                tagColor: "primary",
                i18n: "Components",
                submenu: [
                    {
                        url: '/components/alert',
                        name: "Alert",
                        slug: "componentAlert",
                        i18n: "Alert",
                    },
                    {
                        url: '/components/avatar',
                        name: "Avatar",
                        slug: "componentAvatar",
                        i18n: "Avatar",
                    },
                    {
                        url: '/components/breadcrumb',
                        name: "Breadcrumb",
                        slug: "componentBreadcrumb",
                        i18n: "Breadcrumb",
                    },
                    {
                        url: '/components/button',
                        name: "Button",
                        slug: "componentButton",
                        i18n: "Button",
                    },
                    {
                        url: '/components/chip',
                        name: "Chip",
                        slug: "componentChip",
                        i18n: "Chip",
                    },
                    {
                        url: '/components/collapse',
                        name: "Collapse",
                        slug: "componentCollapse",
                        i18n: "Collapse",
                    },
                    {
                        url: '/components/dialogs',
                        name: "Dialogs",
                        slug: "componentDialog",
                        i18n: "Dialogs",
                    },
                    {
                        url: '/components/divider',
                        name: "Divider",
                        slug: "componentDivider",
                        i18n: "Divider",
                    },
                    {
                        url: '/components/dropdown',
                        name: "DropDown",
                        slug: "componentDropDown",
                        i18n: "DropDown",
                    },
                    {
                        url: '/components/list',
                        name: "List",
                        slug: "componentList",
                        i18n: "List",
                    },
                    {
                        url: '/components/loading',
                        name: "Loading",
                        slug: "componentLoading",
                        i18n: "Loading",
                    },
                    {
                        url: '/components/navbar',
                        name: "Navbar",
                        slug: "componentNavbar",
                        i18n: "Navbar",
                    },
                    {
                        url: '/components/notifications',
                        name: "Notifications",
                        slug: "componentNotifications",
                        i18n: "Notifications",
                    },
                    {
                        url: '/components/pagination',
                        name: "Pagination",
                        slug: "componentPagination",
                        i18n: "Pagination",
                    },
                    {
                        url: '/components/popup',
                        name: "Popup",
                        slug: "componentPopup",
                        i18n: "Popup",
                    },
                    {
                        url: '/components/progress',
                        name: "Progress",
                        slug: "componentProgress",
                        i18n: "Progress",
                    },
                    {
                        url: '/components/sidebar',
                        name: "Sidebar",
                        slug: "componentSidebar",
                        i18n: "Sidebar",
                    },
                    {
                        url: '/components/slider',
                        name: "Slider",
                        slug: "componentSlider",
                        i18n: "Slider",
                    },
                    {
                        url: '/components/tabs',
                        name: "Tabs",
                        slug: "componentTabs",
                        i18n: "Tabs",
                    },
                    {
                        url: '/components/tooltip',
                        name: "Tooltip",
                        slug: "componentTooltip",
                        i18n: "Tooltip",
                    },
                    {
                        url: '/components/upload',
                        name: "Upload",
                        slug: "componentUpload",
                        i18n: "Upload",
                    },
                ]
            },

            {
                header: "Forms",
                i18n: "Forms",
            },
            {
                url: null,
                name: "Form Elements",
                icon: "CopyIcon",
                tagColor: "danger",
                i18n: "FormElements",
                submenu: [
                    {
                        url: '/forms/form-elements/select',
                        name: "Select",
                        slug: "formElementSelect",
                        i18n: "Select",
                    },
                    {
                        url: '/forms/form-elements/switch',
                        name: "Switch",
                        slug: "formElementSwitch",
                        i18n: "Switch",
                    },
                    {
                        url: '/forms/form-elements/checkbox',
                        name: "Checkbox",
                        slug: "formElementCheckbox",
                        i18n: "Checkbox",
                    },
                    {
                        url: '/forms/form-elements/radio',
                        name: "Radio",
                        slug: "formElementRadio",
                        i18n: "Radio",
                    },
                    {
                        url: '/forms/form-elements/input',
                        name: "Input",
                        slug: "formElementInput",
                        i18n: "Input",
                    },
                    {
                        url: '/forms/form-elements/number-input',
                        name: "Number Input",
                        slug: "formElementNumberInput",
                        i18n: "NumberInput",
                    },
                    {
                        url: '/forms/form-elements/textarea',
                        name: "Textarea",
                        slug: "formElementTextarea",
                        i18n: "Textarea",
                    },
                ]
            },
            {
                url: '/forms/form-layouts',
                name: "Form Layouts",
                icon: "PackageIcon",
                slug: "formsFormLayouts",
                i18n: "FormLayouts",
            },
            {
                url: '/forms/form-wizard',
                name: "Form Wizard",
                icon: "PackageIcon",
                tag: 'New',
                tagColor: 'danger',
                slug: "extraComponentFormWizard",
                i18n: "FormWizard",
            },
            {
                url: '/forms/form-validation',
                name: "Form Validation",
                icon: "CheckCircleIcon",
                slug: "extraComponentFormValidation",
                i18n: "FormValidation",
            },
            {
                header: "Pages",
                i18n: "Pages",
            },
            {
                url: null,
                name: "Authentication",
                icon: "PieChartIcon",
                i18n: "Authentication",
                submenu: [
                    {
                        url: '/pages/login',
                        name: "Login",
                        slug: "pagesLogin",
                        i18n: "Login",
                        target: '_blank',
                    },
                    {
                        url: '/pages/register',
                        name: "Register",
                        slug: "pagesRegister",
                        i18n: "Register",
                        target: '_blank',
                    },
                    {
                        url: '/pages/forgot-password',
                        name: "Forgot Password",
                        slug: "pagesForgotPassword",
                        i18n: "ForgotPassword",
                        target: '_blank',
                    },
                    {
                        url: '/pages/reset-password',
                        name: "Reset Password",
                        slug: "pagesResetPassword",
                        i18n: "ResetPassword",
                        target: '_blank',
                    },
                    {
                        url: '/pages/lock-screen',
                        name: "Lock Screen",
                        slug: "pagesLockScreen",
                        i18n: "LockScreen",
                        target: '_blank',
                    },
                ]
            },
            {
                url: '/pages/comingsoon',
                name: "Coming Soon",
                icon: "ClockIcon",
                i18n: "ComingSoon",
                target: '_blank',
            },
            {
                url: null,
                name: "Error",
                icon: "AlertOctagonIcon",
                i18n: "Error",
                submenu: [
                    {
                        url: '/pages/error-404',
                        name: "404",
                        slug: "pageError404",
                        i18n: "404",
                        target: '_blank',
                    },
                    {
                        url: '/pages/error-500',
                        name: "500",
                        slug: "pageError500",
                        i18n: "500",
                        target: '_blank',
                    }
                ]
            },
            {
                url: '/pages/not-authorized',
                name: "Not Authorized",
                slug: "pageNotAuthorized",
                icon: "XCircleIcon",
                i18n: "NotAuthorized",
                target: '_blank',
            },
            {
                url: '/pages/maintenance',
                name: "Maintenance",
                slug: "pageMaintenance",
                icon: "AnchorIcon",
                i18n: "Maintenance",
                target: '_blank',
            },
            {
                url: '/pages/profile',
                name: "Profile",
                icon: "UserIcon",
                i18n: "Profile",

            },
            {
                url: '/pages/faq',
                name: "FAQ",
                icon: "HelpCircleIcon",
                i18n: "FAQ",

            },
            {
                url: '/pages/knowledge-base',
                name: "Knowledge Base",
                icon: "InfoIcon",
                i18n: "KnowledgeBase",

            },
            {
                url: '/pages/search',
                name: "Search",
                icon: "SearchIcon",
                i18n: "Search",

            },
            {
                url: '/pages/invoice',
                name: "Invoice",
                icon: "InfoIcon",
                i18n: "Invoice",

            },
            {
                header: "Charts & Maps",
                i18n: "ChartsAndMaps",
            },
            {
                url: null,
                name: "Charts",
                icon: "PieChartIcon",
                tag: '3',
                tagColor: 'success',
                i18n: "Charts",
                submenu: [
                    {
                        url: '/charts-and-maps/charts/apex-charts',
                        name: "Apex Charts",
                        slug: "extraComponentChartsApexCharts",
                        i18n: "ApexCharts",
                    },
                    {
                        url: '/charts-and-maps/charts/chartjs',
                        name: "chartjs",
                        slug: "extraComponentChartsChartjs",
                        i18n: "chartjs",
                    },
                    {
                        url: '/charts-and-maps/charts/echarts',
                        name: "echarts",
                        slug: "extraComponentChartsEcharts",
                        i18n: "echarts",
                    },
                ]
            },
            {
                url: '/charts-and-maps/maps/google-map',
                name: "Google Map",
                icon: "MapIcon",
                slug: "extraComponentMapsGoogleMap",
                i18n: "GoogleMap",
            },
            {
                header: "Extensions",
                i18n: "Extensions",
            },
            {
                url: '/extensions/select',
                name: "Select",
                icon: "PocketIcon",
                slug: "extraComponentSelect",
                i18n: "Select",
            },
            {
                url: '/extensions/quill-editor',
                name: "Quill Editor",
                icon: "EditIcon",
                slug: "extraComponentQuillEditor",
                i18n: "QuillEditor",
            },
            {
                url: '/extensions/drag-and-drop',
                name: "Drag & Drop",
                icon: "DropletIcon",
                slug: "extraComponentDragAndDrop",
                i18n: "DragAndDrop",
            },
            {
                url: '/extensions/datepicker',
                name: "Datepicker",
                icon: "CalendarIcon",
                slug: "extraComponentDatepicker",
                i18n: "Datepicker",
            },
            {
                url: null,
                name: 'Access Control',
                icon: 'LockIcon',
                i18n: 'AccessControl',
                submenu: [
                    {
                        url: '/extensions/access-control-editor',
                        name: "Editor View",
                        slug: "extraComponentAccessControlEditor",
                        i18n: "EditorView",
                    },
                    {
                        url: '/extensions/access-control-admin',
                        name: "Admin View",
                        slug: "extraComponentAccessControlAdmin",
                        i18n: "AdminView",
                    },
                ]
            },
            {
                url: '/extensions/i18n',
                name: "I18n",
                icon: "GlobeIcon",
                slug: "extraComponentI18n",
                i18n: "I18n",
            },
            {
                url: '/extensions/carousel',
                name: "Carousel",
                icon: "LayersIcon",
                slug: "extraComponentCarousel",
                i18n: "Carousel",
            },
            {
                url: '/extensions/clipboard',
                name: "Clipboard",
                icon: "CopyIcon",
                slug: "extraComponentClipboard",
                i18n: "Clipboard",
            },
            {
                url: '/extensions/context-menu',
                name: "Context Menu",
                icon: "MoreHorizontalIcon",
                slug: "extraComponentContextMenu",
                i18n: "ContextMenu",
            },
            {
                header: "Others",
                i18n: "Others",
            },
            {
                url: null,
                name: "Menu Levels",
                icon: "MenuIcon",
                i18n: "MenuLevels",
                submenu: [
                    {
                        url: null,
                        name: "Menu Level 2.1",
                        i18n: "MenuLevel2p1",
                    },
                    {
                        url: null,
                        name: "Menu Level 2.2",
                        i18n: "MenuLevel2p2",
                        submenu: [
                            {
                                url: null,
                                name: "Menu Level 3.1",
                                i18n: "MenuLevel3p1",
                            },
                            {
                                url: null,
                                name: "Menu Level 3.2",
                                i18n: "MenuLevel3p2",
                            },
                        ]
                    },
                ]
            },
            {
                url: null,
                name: "Disabled Menu",
                icon: "EyeOffIcon",
                i18n: "DisabledMenu",
                isDisabled: true,
            },
            {
                header: "Support",
                i18n: "Support",
            },
            {
                url: 'https://pixinvent.com/demo/vuesax-vuejs-admin-dashboard-template/documentation/',
                name: "Documentation",
                icon: "BookOpenIcon",
                slug: "external",
                i18n: "Documentation",
                target: "_blank"
            },
            {
                url: 'https://pixinvent.ticksy.com/',
                name: "Raise Support",
                icon: "LifeBuoyIcon",
                slug: "external",
                i18n: "RaiseSupport",
                target: "_blank"
            },

*/
]
