<!-- =========================================================================================

    File Name: NotificationButton.vue

========================================================================================== -->

<template>
    <feather-icon @click="iconClick()" class="cursor-pointer cursor-pointer mt-1 sm:mr-6 mr-2" icon="BellIcon"
                  :badge="unReadCount"></feather-icon>
</template>

<script>
    const alarmSound = new Audio("/notificationAlarm.mp3");
    export default {
        data() {
            return {
                unReadCount: 0,
                notificationItems: [],
                notificationInterval: null,
                isShowNotify : false,

            }
        },
        computed : {

                storeId() {
                    const store = localStorage.getItem("store");
                    return store === "Emaar" ? 1 : store === "Umraniye" ? 5 : 8;
                },
                storeName() {
                    return localStorage.getItem("store");
                }

        },
        mounted() {
            this.controlNotification();
            this.notificationInterval = setInterval(() => {

                this.controlNotification();
            }, 400000);
        },
        destroyed() {
            clearInterval(this.notificationInterval);
        },
        methods: {


            iconClick() {
                this.unReadCount = 0;
                this.$emit('iconClick')
            },
            controlNotification() {



                    // $socket is socket.io-client instance
                    //this.$socket.emit('emit_method', data)



               /* this.$http.get(`https://iot.buybuddy.co:7776/iot/device/heartbeats/store/${this.storeId}`)
                    .then(({data}) => {
                        this.notificationItems = data.data.filter(item => item.set_state === -103);
                        this.unReadCount = this.notificationItems.length;
                       // this.unReadCount = Math.random() > 0.2 ? 1 : 0;
                        this.unReadCount && this.showNotification();
                    });*/
            },
            showNotification() {



                alarmSound.currentTime = 0;
                alarmSound.play();

                if(this.isShowNotify) {
                    return false;
                }

                this.isShowNotify = true;

                this.$vs.notify({
                    fixed: true,
                    title: 'Şuan Öten Alarm Sayısı ',
                    text: this.unReadCount,
                    color: 'danger',
                    click: () => {
                        this.unReadCount = 0;
                        this.isShowNotify = false;
                        alarmSound.pause();
                        this.$emit('notificationClick');
                    }
                });
            }
        }
    }
</script>
